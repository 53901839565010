/*import "/src/styles/bootstrap.min.css"
import "/src/styles/override.css"
import "/src/lib/jquery-2.2.4.js"
import "/src/lib/bootstrap-3.0.2.min.js"*/

import "@popperjs/core/dist/umd/popper.min"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"

import "/src/styles/override.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

import "/plugins/img-comparison-slider.js"
import "/plugins/img-comparison-slider.css"

import "/plugins/gatsby-remark-span/gatsby-remark-span.css"

// in gastby-browser.js
/*
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    const { pathname } = location
    // list of routes for the scroll-to-top-hook
    //const scrollToTopRoutes = [`/privacy-policy`, `/page-2`]
    // if the new route is part of the list above, scroll to top (0, 0)
    //if (scrollToTopRoutes.indexOf(pathname) !== -1) {
        console.log("scroll");
      //window.scrollTo(0, 170);
   // }
  
   // return false;
    return [0, 0];
  }*/

    export const onClientEntry = () => {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://scripts.simpleanalyticscdn.com/latest.js";
      document.body.appendChild(script);
    };
    